/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';

import { authReducer, AUTH_REDUCER_KEY } from 'companion-app-components/flux/auth';
import { bumpReducer, BUMP_REDUCER_KEY } from 'companion-app-components/flux/bump';
import { categoriesReducer, CATEGORIES_REDUCER_KEY } from 'companion-app-components/flux/categories';
import { featureFlagsReducer, FEATURE_FLAGS_REDUCER_KEY } from 'companion-app-components/flux/feature-flags';
import { coreActions } from 'companion-app-components/flux/core-actions';
import { datasetsReducer, DATASETS_REDUCER_KEY } from 'companion-app-components/flux/datasets';
import { postponedActionsReducer, POSTPONED_ACTIONS_REDUCER_KEY } from 'companion-app-components/flux/postponed-actions';
import { profileReducer, PROFILE_REDUCER_KEY } from 'companion-app-components/flux/profile';
import { tagsReducer, TAGS_REDUCER_KEY } from 'companion-app-components/flux/tags';
import { undoReducer, UNDO_REDUCER_KEY } from 'companion-app-components/flux/undo';
import { TRANSACTIONS_REDUCER_KEY } from 'companion-app-components/flux/transactions';
import { scheduledTransactionsReducer, SCHEDULED_TRANSACTIONS_REDUCER_KEY } from 'companion-app-components/flux/scheduled-transactions';
import investmentHoldingsReducer, { INVESTMENT_HOLDINGS_REDUCER_KEY } from 'companion-app-components/flux/investmentHoldings/reducer';
import { renameRulesReducer, REDUCER_KEY as RULES_REDUCER_KEY } from 'companion-app-components/flux/rename-rules';
import { budgetsReducer, BUDGETS_REDUCER_KEY } from 'companion-app-components/flux/budgets';
import { configFeatureFlagsReducer, CONFIG_FF_REDUCER_KEY } from 'companion-app-components/flux/config-feature-flags';
import { memorizedRulesReducer, REDUCER_KEY as MEMORIZED_RULES_REDUCER_KEY } from 'companion-app-components/flux/memorized-rules';
import { budgetItemsReducer, BUDGET_ITEMS_REDUCER_KEY } from 'companion-app-components/flux/budget-items';
import { dateReducer, DATE_REDUCER_KEY } from 'companion-app-components/flux/date';
import { categoryGroupsReducer, CATEGORY_GROUPS_REDUCER_KEY } from 'companion-app-components/flux/category-groups';
import { categoryGroupListsReducer, CATEGORY_GROUP_LISTS_REDUCER_KEY } from 'companion-app-components/flux/category-group-lists';
import { PREFERENCES_REDUCER_KEY } from 'companion-app-components/flux/preferences';
import { datasetSharesReducer, DATASET_SHARES_REDUCER_KEY } from 'companion-app-components/flux/dataset-shares';
import { userInfoReducer, USERINFO_REDUCER_KEY } from 'companion-app-components/flux/user-info';

import uiStateReducer, { UISTATE_REDUCER_KEY } from 'components/UIState/reducer';
import { reducer as accountsReducer, ACCOUNTS_REDUCER_KEY } from 'data/accounts/reducer';
import accountDiscoveryReducer, { REDUCER_KEY as ACCOUNT_DISCOVERY_REDUCER_KEY } from 'components/Dialogs/AccountDiscovery/reducer';
import appReducer, { REDUCER_KEY as APP_REDUCER_KEY } from 'data/app/reducer';
import clipboardReducer, { REDUCER_KEY as CLIPBOARD_REDUCER_KEY } from 'data/clipboard/reducer';
import documentsReducer, { REDUCER_KEY as DOCUMENTS_REDUCER_KEY } from 'data/documents/reducer';
import institutionsReducer, { REDUCER_KEY as INSTITUTIONS_REDUCER_KEY } from 'data/institutions/reducer';
import institutionLoginsReducer, { REDUCER_KEY as INSTITUTION_LOGINS_REDUCER_KEY } from 'data/institutionLogins/reducer';
import persistReducer, { REDUCER_KEY as PERSIST_REDUCER_KEY } from 'data/persist/reducer';
import preferencesReducer from 'data/preferences/reducer';
import preferencesV2Reducer, { PREFERENCES_V2_REDUCER_KEY } from 'data/preferencesV2/preferencesV2Reducer';
import rootUiReducer, { REDUCER_KEY as ROOTUI_REDUCER_KEY } from 'data/rootUi/reducer';
import stdFormReducer, { REDUCER_KEY as STD_FORM_DATA_REDUCER_KEY } from 'data/StdForm/reducer';
import subscriptionReducer, { SUBSCRIPTIONS_REDUCER_KEY } from 'data/subscriptions/subscriptionsReducer';
import transactionsReducer from 'data/transactions/reducer';
import payeesReducer, { REDUCER_KEY as PAYEES_REDUCER_KEY } from 'data/payees/reducer';
import entitlemtnsReducer, { ENTITLEMENTS_REDUCER_KEY } from 'data/entitlements/entitlementsReducer';
import mcRulesReducer, { MC_RULES_REDUCER_KEY } from 'data/mcRules/mcRulesReducer';
import notificationsReducer, { NOTIFICATIONS_REDUCER_KEY } from 'data/notifications/notificationsReducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

// Combine Global App Reducers. The injectedReducers argument is required to
// suuport hot-loading for dynamically loaded reducers.
//
const combineAppReducers = (injectedReducers) => {
  const reducers = {
    // TODO: maybe only register reducers that pertain to each app?

    // TODO: MAKE SURE THERE ARE NO DUPLICATE ENTRIES
    //  ADD THINGS IN ALPHABETICAL ORDER PLEASE
    [ACCOUNT_DISCOVERY_REDUCER_KEY]: accountDiscoveryReducer,
    [ACCOUNTS_REDUCER_KEY]: accountsReducer,
    [APP_REDUCER_KEY]: appReducer,
    [AUTH_REDUCER_KEY]: authReducer,
    [BUDGET_ITEMS_REDUCER_KEY]: budgetItemsReducer,
    [BUDGETS_REDUCER_KEY]: budgetsReducer,
    [BUMP_REDUCER_KEY]: bumpReducer,
    [CATEGORIES_REDUCER_KEY]: categoriesReducer,
    [CATEGORY_GROUP_LISTS_REDUCER_KEY]: categoryGroupListsReducer,
    [CATEGORY_GROUPS_REDUCER_KEY]: categoryGroupsReducer,
    [CLIPBOARD_REDUCER_KEY]: clipboardReducer,
    [CONFIG_FF_REDUCER_KEY]: configFeatureFlagsReducer,
    [DATASETS_REDUCER_KEY]: datasetsReducer,
    [DATE_REDUCER_KEY]: dateReducer,
    [DOCUMENTS_REDUCER_KEY]: documentsReducer,
    [ENTITLEMENTS_REDUCER_KEY]: entitlemtnsReducer,
    [FEATURE_FLAGS_REDUCER_KEY]: featureFlagsReducer,
    [INSTITUTION_LOGINS_REDUCER_KEY]: institutionLoginsReducer,
    [INSTITUTIONS_REDUCER_KEY]: institutionsReducer,
    [INVESTMENT_HOLDINGS_REDUCER_KEY]: investmentHoldingsReducer,
    [MC_RULES_REDUCER_KEY]: mcRulesReducer,
    [MEMORIZED_RULES_REDUCER_KEY]: memorizedRulesReducer,
    [NOTIFICATIONS_REDUCER_KEY]: notificationsReducer,
    [PAYEES_REDUCER_KEY]: payeesReducer,
    [PERSIST_REDUCER_KEY]: persistReducer,
    [POSTPONED_ACTIONS_REDUCER_KEY]: postponedActionsReducer,
    [PREFERENCES_REDUCER_KEY]: preferencesReducer,
    [PREFERENCES_V2_REDUCER_KEY]: preferencesV2Reducer,
    [PROFILE_REDUCER_KEY]: profileReducer,
    [ROOTUI_REDUCER_KEY]: rootUiReducer,
    [RULES_REDUCER_KEY]: renameRulesReducer,
    [SCHEDULED_TRANSACTIONS_REDUCER_KEY]: scheduledTransactionsReducer,
    [DATASET_SHARES_REDUCER_KEY]: datasetSharesReducer,
    [USERINFO_REDUCER_KEY]: userInfoReducer,
    [STD_FORM_DATA_REDUCER_KEY]: stdFormReducer,
    [SUBSCRIPTIONS_REDUCER_KEY]: subscriptionReducer,
    [TAGS_REDUCER_KEY]: tagsReducer,
    [TRANSACTIONS_REDUCER_KEY]: transactionsReducer,
    [UISTATE_REDUCER_KEY]: uiStateReducer,
    [UNDO_REDUCER_KEY]: undoReducer,
    ...injectedReducers,
    // TODO: MAKE SURE THERE ARE NO DUPLICATE ENTRIES
    //  ADD THINGS IN ALPHABETICAL ORDER PLEASE
  };
  return combineReducers(Object.keys(reducers).sort().reduce((reducersSorted, key) => { reducersSorted[key] = reducers[key]; return reducersSorted; }, {})); // eslint-disable-line no-param-reassign
};

// Create a Root Reducer which wraps and delegates to the App Reducers. The
// root reducer is needed to handle resetting of the redux store when a user
// logs out.
//
export default function createReducer(injectedReducers) {
  const appReducers = combineAppReducers(injectedReducers);
  return (state, action) => {
    switch (action.type) {
      case coreActions.setRoot.toString():
        return action.payload;

      default:
        return appReducers(state, action);
    }
  };
}

